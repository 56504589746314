<template>
    <div class="mx-4 mt-3">
        <div class="form-container">
            <div class="row justify-content-center">
              <div class="col-9">
                <form @submit.prevent="handleSubmit">
                  <div class="row row-sm text-left">
                    <div class="col-12 text-center">
                      <button :disabled="loading" type="submit" class="btn btn-primary-red-10" >Change LingWing Password</button>
                    </div>
                  </div>
                </form>
              </div>
  
            </div>
        </div>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
    import { mapGetters } from 'vuex';
    export default {
      name: 'change-lingwing-password',
  
      data() {
        return {
          loading: false
        }
      },
      computed:{

        ...mapGetters({
        user: 'auth/user',
   
      })

      },
 
  
      methods: {
        changeLingwingPassword() {

            var email=this.user.email_UG
         
          this.loading = true;
          axios.post('https://api-1.lingwing.com/api/v2/public/auth/forgot',{email})
            .then(response => {
            
              this.helpers.notifySuccessMessage(response.data.message);
            })
            .catch(error => {
              this.helpers.notifyErrorMessage(error);
            })
            .finally(() => {
              this.loading = false;
            })
        },
  
        handleSubmit() {
          this.$swal({
            title: "Change LingWing Password",
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.isConfirmed) {
             this.changeLingwingPassword();
            }
          });
        }
      }
    };
  </script>
  
  <style scoped>
  
  </style>
  