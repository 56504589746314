<template>
  <div class="container-fluid">
    <div class="row">

        <sidebar></sidebar>
           <!-- MAIN CONTENT start -->
            <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
                <div class="row">
                    <div class="col-12 ">
                        <app-header></app-header>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>
                    </div>
                </div>
                <!-- Exam View section -->
                <section class="exam-view-section d-flex align-items-center justify-content-center px-0 mx-0 px-xl-5 mx-xl-5 mt-lg-2 mb-3">
                    <div v-if="exams.length > 0" class="row align-items-center">
                        <div class="col-12"  v-for="exam of exams">
                            <!-- subject 1 -->
                            <div class="card mb-4">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <h2 class="mb-0"><span>{{ exam.subject_code }}</span>  {{ helpers.getLocalizedField(exam, 'subject') }}</h2>
                                    <div>
                                      <!-- {{examType}} -->
                                      <router-link :key="$index"
                                                   v-for="(examType, $index) of exam.exams" class="btn btn-main"
                                                   :to="'/exam-view/' + examType.unitID + '/' + examType.max_examscheduleID + '/' + examType.exam_numberID">
                                        {{ helpers.getLocalizedField(examType, 'exam') }}
                                      </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row align-items-center w-100">
                      <h2 class="w-100 text-center">{{ $t('exam-view.result-not-found') }}</h2>
                    </div>
                </section>


            </main>
            <!-- MAIN CONTETN end -->
      </div>
    </div>

</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import { mapGetters } from 'vuex';

  export default {
    name: 'exam-view',
    components: { sidebar, appHeader, pageHeader },

    data() {
      return {

      }
    },

    computed: {
      pageData() {
        return {
          title: this.$t('exam-view.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('exam-view.title'),
              link: '/exam-view',
              isActive: true,
            }],
        };
      },
      ...mapGetters({
        exams: 'exam/maxExams',
      })
    },

    methods: {
      loadExams() {
        return this.$store.dispatch('exam/loadMaxExams');
      }
    },

    mounted() {
      this.loadExams();
    }
  };
</script>
<style lang="scss">
  .btn-main {
    max-width: none !important;
  }
</style>
