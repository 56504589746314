<template>


  <div class="container-fluid">
    <div class="row">

        <sidebar></sidebar>
        <!-- MAIN CONTENT start -->
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10">
                <div class="row">
                    <div class="col-12 mb-5">
                        <app-header></app-header>
                        <p class="f-p2 text-center" style="font-size: 20px;color:red">{{ helpers.getLocalizedField(user, 'studentStatus') }}</p>
                        <page-header :page-title="pageData.title" :breadcrumb="pageData.breadcrumb"></page-header>

                        
                    </div>
                </div>
                <div class="row mx-md-5 px-md-5">
                  <div class="col-md-12 col-lg-6 mb-5 mb-lg-0">
                    <change-password></change-password>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <change-picture></change-picture>
                    <change-teams-password></change-teams-password>
                    <lingwing-password-change></lingwing-password-change>
                  </div>
                </div>
                <div class="row mx-md-5 px-md-5">
                  <div class="col-12">
                        <change-information></change-information>
                  </div>
                </div>

        </main>
    </div>
  </div>


</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import ChangePassword from '../components/profile/change-password';
import ChangeInformation from '../components/profile/change-information';
import ChangePicture from '../components/profile/change-picture';
import ChangeTeamsPassword from '../components/profile/change-teams-password';
import LingwingPasswordChange from '../components/profile/lingwing-password-change';
import { mapGetters } from 'vuex';

export default {
  name: 'Profile',
  components: { ChangeInformation, ChangePicture, ChangePassword, ChangeTeamsPassword, LingwingPasswordChange,sidebar, appHeader, pageHeader },
  computed: {
    pageData() {
      return {
        title: this.$t('profile.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
        },
          {
          icon: '',
          title: this.$t('profile.title'),
          link: '/profile',
          isActive: true,
        }],
      };
    },
    ...mapGetters('auth', [
      'studentPicture',
      'user',
    ]),
  },
};
</script>

<style scoped>

</style>
